<template>
  <div id="componentMain">
    
    <!-- CURRENT ROUTE -->
    <div id="currentRouteElement" hidden>{{router.currentRoute.value.fullPath}}</div>


    <!-- SIDEMENU -->
    <div id="sideMenu">

      <!-- profile picture -->
      <div id="sideMenuSectionProfilePicture" class="sideMenuSection" v-on:click="setRoute('/about')">
      </div>

      <!-- contact details -->
      <div id="sideMenuSectionContact" class="sideMenuSection">
        
        <!-- contact: email -->
        <div id="" class="sideMenuContactLinkContainer">
          <img id="" class="sideMenuContactLinkIcon iconRound" title="" v-on:click="linkSideMenuContact('email', contactEmail)" v-bind:src="'/icons-for-links/icon-email.png'" />
          <p class="sideMenuContactName">{{contactEmail}}</p>
        </div>

        <!-- contact: x -->
        <div id="" class="sideMenuContactLinkContainer">
          <img id="" class="sideMenuContactLinkIcon iconRound" v-bind:title="contactX" v-on:click="linkSideMenuContact('x', 'dev51460')" v-bind:src="'/icons-for-links/icon-x.png'" />
          <!-- <p class="sideMenuContactName">{{contactX}}</p> -->
        </div>
        
        <!-- contact: discord -->
        <div id="" class="sideMenuContactLinkContainer">
          <img id="" class="sideMenuContactLinkIcon iconRound" v-bind:title="contactDiscordUsername" v-on:click="linkSideMenuContact('discord', contactDiscordId)" v-bind:src="'/icons-for-links/icon-discord.png'" />
          <!-- <p class="sideMenuContactName">{{contactDiscordUsername}}</p> -->
        </div>

        <!-- contact: github -->
        <div id="" class="sideMenuContactLinkContainer">
          <img id="" class="sideMenuContactLinkIcon iconRound" v-bind:title="contactGithub" v-on:click="linkSideMenuContact('github', contactGithub)" v-bind:src="'/icons-for-links/icon-github.png'" />
          <!-- <p id="contactNameGithub" class="sideMenuContactName">{{contactGithub}}</p> -->
        </div>

        <!-- contact: play store -->
        <div id="" class="sideMenuContactLinkContainer">
          <img id="" class="sideMenuContactLinkIcon iconRound" v-bind:title="contactPlayStore" v-on:click="linkSideMenuContact('android-play-store', contactPlayStore)" v-bind:src="'/icons-for-links/icon-android-play-store.png'"/>
          <!-- <p class="sideMenuContactName">{{contactPlayStore}}</p> -->
        </div>

        <!-- contact: store.app -->
        <!-- <div id="" class="sideMenuContactLinkContainer" v-on:click="linkSideMenuContact('', '')">
          <img id="" class="sideMenuContactLinkIcon" title="Store.app" v-bind:src="'/icons-for-links/icon-store.app.png'" />
          <p class="sideMenuContactName">Username</p>
        </div> -->

      </div>
    </div>


    <!-- CATEGORIES -->
    <div id="categoriesMenu">

      <!-- projects -->
      <div id="categoryProjects" class="category" v-on:click="setRoute('/projects')">PROJECTS</div>
      <p class="dotCategory"> · </p>
      
      <!-- education -->
      <div id="categoryEducation" class="category" v-on:click="setRoute('/education')">EDUCATION</div>
      <p class="dotCategory"> · </p>
      
      <!-- timeline -->
      <div id="categoryTimeline" class="category" v-on:click="setRoute('/timeline')">TIMELINE</div>
      <p class="dotCategory"> · </p>
      
      <!-- about -->
      <div id="categoryAbout" class="category" v-on:click="setRoute('/about')">ABOUT</div>
    </div>


    <!-- SELECTED CATEGORY ITEMS -->
    <div v-bind:id="'selectedCategoryDataSelectedCategory'" class="selectedCategoryData">
      <div v-bind:id="'categoryItem' + (index + 1)" v-for="(item, index) in selectedCategoryData">
        
        <!-- item: project -->
        <div v-bind:id="'categoryItemProject' + (index + 1)" class="categoryItemProject categoryDataObj" 
          v-if="item.type == 'project' && item.hidden == false" v-on:click="setRoute('/projects/' + item.id)">
          
          <!-- project id -->
          <!-- <div class="categoryDataItemId categoryDataItemIdBelow10" v-if="item.id < 10">{{item.id}}</div> -->
          <!-- <div class="categoryDataItemId" v-else-if="item.id >= 10">{{item.id}}</div> -->
          <!-- <p class="dotProjectName"> · </p> -->
          
          <!-- project icon -->
          <img v-bind:src="item.icon" class="categoryItemProject categoryDataItemIcon" />
          <p class="dotProjectName"> · </p>
          
          <!-- project name -->
          <div class="categoryDataItemName">{{item.name_first}}</div>

          <!-- project repos -->
          <div id="projectLinks">

            <!-- frontend -->
            <img v-if="item.technology_frontend == 'Vue.js'" v-bind:src="'/icons-for-links/icon-vue.js.png'" title="Vue.js" class="categoryDataItemLink" />
            <img v-else-if="item.technology_frontend == 'Express'" v-bind:src="'/icons-for-links/icon-express-js.png'" title="Express.js" class="categoryDataItemLink" />
            <img v-else-if="item.technology_frontend == 'EJS'" v-bind:src="'/icons-for-links/icon-EJS.png'" title="EJS" class="categoryDataItemLink" />
            <img v-else-if="item.technology_frontend == 'Embedded JavaScript'" v-bind:src="'/icons-for-links/icon-EJS.png'" title="Embedded JavaScript" class="categoryDataItemLink" />
            <img v-else-if="item.technology_frontend == 'Kivy'" v-bind:src="'/icons-for-links/icon-kivy.png'" title="Kivy" class="categoryDataItemLink iconRound" />
            <img v-else-if="item.technology_frontend == 'PHP'" v-bind:src="'/icons-for-links/icon-php.png'" title="PHP" class="categoryDataItemLink iconRound" />
            <img v-else-if="item.technology_frontend == 'Android'" v-bind:src="'/icons-for-links/icon-android.png'" title="Android" class="categoryDataItemLink" />
            <img v-else-if="item.technology_frontend == 'html5'" v-bind:src="'/icons-for-links/icon-html5.png'" title="HTML 5" class="categoryDataItemLink" />
            <img v-else-if="item.technology_frontend == 'Socket.IO'" v-bind:src="'/icons-for-links/icon-socket.io.png'" title="Socket.IO" class="categoryDataItemLink" />
            
            <!-- backend -->
            <img v-if="item.technology_backend == 'Python'" v-bind:src="'/icons-for-links/icon-python.png'" title="Python" class="categoryDataItemLink" />
            <img v-else-if="item.technology_backend == 'Node.js'" v-bind:src="'/icons-for-links/icon-node.js.png'" title="Node.js" class="categoryDataItemLink" />
            <img v-else-if="item.technology_backend == 'Java'" v-bind:src="'/icons-for-links/icon-java.png'" title="Java" class="categoryDataItemLink" />
            <img v-else-if="item.technology_backend == 'Android'" v-bind:src="'/icons-for-links/icon-android.png'" title="Android" class="categoryDataItemLink" />
            <img v-else-if="item.technology_backend == 'Android Studio'" v-bind:src="'/icons-for-links/icon-android-studio.png'" title="Android Studio" class="categoryDataItemLink" />
            <img v-else-if="item.technology_backend == 'PHP'" v-bind:src="'/icons-for-links/icon-php.png'" title="PHP" class="categoryDataItemLink iconRound" />

            <!-- rest api -->
            <img v-else-if="item.technology_rest_api == 'Python'" v-bind:src="'/icons-for-links/icon-python.png'" title="Python" class="categoryDataItemLink" />
            <img v-else-if="item.technology_rest_api == 'Node.js'" v-bind:src="'/icons-for-links/icon-node.js.png'" title="Node.js" class="categoryDataItemLink" />
            
            <!-- database -->
            <img v-else-if="item.technology_database == 'MySQL'" v-bind:src="'/icons-for-links/icon-mysql.png'" title="MySQL" class="categoryDataItemLink" />
          </div> 
        </div>
        
        <!-- item: education -->
        <div v-bind:id="'categoryItemEducation' + (index + 1)" class="categoryItemEducation categoryDataObj" 
        v-else-if="item.type == 'education' && item.hidden == false" v-on:click="setRoute('/education/' + item.id)">
          
          <div class="educationDataItem" v-if="item.type == 'education'">

            <!-- education id -->
            <!-- <p class="educationItemId">{{item.id}}</p> -->
            <!-- <p class="dotEducationItem"> · </p> -->

            <!-- education icon -->
            <div class="categoryItem">
              <img class="categoryDataItemIcon categoryEducationIcon" v-bind:src="item.icon" v-bind:title='item.type_of_education'/>
            </div>
            
            <!-- education name -->
            <p class="dotEducationItem"> · </p>
            <p class="educationItemName">{{item.name}}</p>
          </div>
        </div>

        <!-- item: timeline -->
        <div v-bind:id="'categoryItemTimeline' + (index + 1)" class="categoryItemTimeline" v-else-if="item.type == 'timeline'">
          
          <!-- timeline date -->
          <div class="timelineDate">{{item.date}}</div>
          <p class="dotTimelineItem"> · </p>

          <!-- timeline value -->
          <div class="timelineItem" v-for="obj in item.items">
            <p class="timelineText">{{obj}}</p>
          </div>
        </div>
        
        <!-- item: about -->
        <div class="aboutItemContainer" v-else-if="item.type == 'about'">

          <!-- item category title -->
          <div v-bind:id="'aboutCategoryTitle' + item.value" class="aboutCategoryTitle" v-if="item.category == 'categoryTitle'">{{item.value}}</div>
          
          <!-- item category value -->
          <div id="" class="aboutCategoryItem" v-else>
            <p v-if="!item.icon" class="aboutItemText">{{item.category.toString().replace("_", " ")}} · {{item.value}}</p>

            <div class="" v-if="item.icon">
              <img class="aboutItemIconSquare" v-if="item.value == 'VS Code'"  v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" />
              <img class="aboutItemIconSquare" v-else-if="item.value == 'Vue'"  v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" />
              <img class="aboutItemIconSquare" v-else-if="item.value == 'Node'"  v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" />
              <img class="aboutItemIconSquare" v-else-if="item.value == 'Python'"  v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" />
              <!-- <img class="aboutItemIconSquare" v-else-if="item.value == 'JS'"  v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" /> -->
              <img class="aboutItemIconSquare" v-else-if="item.value == 'C#'"  v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" />
              <img class="aboutItemIconRounded" v-else v-bind:src="item.icon" v-bind:title="item.category.toString().replace('_', ' ') + ' · ' + item.value" />
              
              <p class="aboutItemIconText">{{item.value}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- MODAL: underlay -->
    <div id="modalUnderlay" v-on:click="undisplayModals()">
    </div>


    <!-- MODAL: selected item -->
    <div id="modalItemDetails" v-if="selectedCategoryItem">
      
      <!-- project: banner -->
       <div id="projectBanner">
          
            <!-- project icon -->
            <img id="dataItemProjectIcon" v-bind:src="selectedCategoryItem.icon" />

            <!-- detail categories -->
            <div id="dataItemCategories" v-if="selectedCategoryItem.type == 'project'">
              <div id="dataItemCategoryAbout" class="dataItemCategory" v-on:click="selectProjectDetailsCategory('about')">About</div>
              <p class="dotCategoryItem"> · </p>
              
              <div id="dataItemCategoryPreview" class="dataItemCategory" v-on:click="selectProjectDetailsCategory('preview')">Preview</div>
              <p class="dotCategoryItem"> · </p>
              
              <div id="dataItemCategoryInstallers" class="dataItemCategory" v-on:click="selectProjectDetailsCategory('installers')">Installers</div>
              <!-- <div class="dataItemCategory">Technologies</div> -->
            </div>
      
            <!-- project name -->
            <div id="dataItemProjectName" v-if="selectedCategoryItem.type == 'project'">{{selectedCategoryItem.name_first}}</div>
            <div id="dataItemProjectOneSentenceDescriptionDesktop" v-if="selectedCategoryItem.type == 'project'">
              {{selectedCategoryItem.one_sentence_description}}
            </div>
        </div>

        <!-- project: about -->
        <div id="dataItemCategoryDataAbout" class="dataItemCategoryData" v-if="selectedCategoryItem.type == 'project'">
          
          <!-- logo -->
          <!-- <div id="projectAboutSectionLogo" class="projectAboutSection">
            <p class="projectAboutTitle">Logo</p>
            <img id="dataItemProjectIcon" v-bind:src="selectedCategoryItem.icon" />
          </div> -->

          <!-- slogan -->
          <div id="projectAboutSectionSlogan" class="projectAboutSection">
            <p class="projectAboutTitle">Slogan</p>
            <div id="dataItemProjectOneSentenceDescription" v-if="selectedCategoryItem.type == 'project'">
              {{selectedCategoryItem.one_sentence_description}}
            </div>
          </div>

          <!-- purpose -->
          <div id="projectAboutSectionWhy" class="projectAboutSection">
            <p class="projectAboutTitle">Purpose</p>
            <p class="projectAboutText">{{selectedCategoryItem.purpose}}</p>
          </div>

          <!-- technologies -->
          <div id="projectAboutSectionTechnologies" class="projectAboutSection">
            <p class="projectAboutTitle">Technologies</p>
            <div id="dataItemLinksTechnologies">

              <!-- frontend -->
              <span id="" v-if="selectedCategoryItem.technology_frontend">
                <img id="" class="projectLink iconRound" v-if="selectedCategoryItem.technology_frontend == 'Kivy'" v-bind:src="'/icons-for-links/icon-kivy.png'" title="Kivy" />
                <img id="" class="projectLink iconRound" v-else-if="selectedCategoryItem.technology_frontend == 'PHP'" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_frontend.toLowerCase() + '.png'" title="PHP" />
                <img id="" class="projectLink"           v-else-if="selectedCategoryItem.technology_frontend == 'Embedded JavaScript'" v-bind:src="'/icons-for-links/icon-EJS.png'" title="Embedded JavaScript" />
                <img id="" class="projectLink"           v-else-if="selectedCategoryItem.technology_frontend == 'html5'" v-bind:src="'/icons-for-links/icon-html5.png'" title="HTML 5" />
                <img id="" class="projectLink"           v-else v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_frontend.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_frontend" />
              </span>

              <!-- backend -->
              <span id="" v-if="selectedCategoryItem.technology_backend">
                <img id="" class="projectLink iconRound" v-if="selectedCategoryItem.technology_backend == 'PHP'" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_backend.toLowerCase() + '.png'" title="PHP" />
                <img id="" class="projectLink"           v-else v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_backend.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_backend" />
              </span>

              <!-- rest api -->
              <span id="">
                <img id="" class="projectLink" v-if="selectedCategoryItem.technology_rest_api" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_rest_api.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_rest_api" />
              </span>


              <!-- database -->
              <span id="">
                <img id="" class="projectLink" v-if="selectedCategoryItem.technology_database" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_database.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_database" />
              </span>

              <!-- others -->
              <span id="">
                <img id="" class="projectLink iconRound" v-if="selectedCategoryItem.technology_other_1 == 'PyTube'" v-bind:src="'/icons-for-links/icon-pytube.png'" title="PyTube" />
                <img id="" class="projectLink" v-else-if="selectedCategoryItem.technology_other_1" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_other_1.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_other_1.replaceAll('-', ' ')" />
                <img id="" class="projectLink" v-if="selectedCategoryItem.technology_other_2" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_other_2.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_other_2.replaceAll('-', ' ')" />
                <img id="" class="projectLink" v-if="selectedCategoryItem.technology_other_3" v-bind:src="'/icons-for-links/icon-' + selectedCategoryItem.technology_other_3.toLowerCase() + '.png'" v-bind:title="selectedCategoryItem.technology_other_3.replaceAll('-', ' ')" />
              </span>
              
              <!-- pwa -->
              <!-- <span id="">
                <img id="projectLinkPWA" class="projectLink" v-if="selectedCategoryItem.technology_pwa == true" v-bind:src="'/icons-for-links/icon-pwa.png'" title="Progressive Web App" />
              </span> -->
            </div>
          </div>

          <div id="projectAboutSectionThirdPartyAPIs" class="projectAboutSection" v-if="selectedCategoryItem.technology_third_party_apis">
            <p class="projectAboutTitle">3rd Party REST APIs</p>

            <div id="projectThirdPartyAPIs">
              <img id="" class="projectLink" v-for="item in selectedCategoryItem.third_party_apis" v-bind:src="item.logo" v-bind:title="item.name" />
            </div>
          </div>

          <!-- platforms -->
          <div id="projectAboutSectionPlatforms" class="projectAboutSection">
            <p class="projectAboutTitle" v-if="selectedCategoryItem.platforms.toString().includes(' · ')">Platforms</p>
            <p class="projectAboutTitle" v-else>Platform</p>
            <p class="projectAboutText">{{selectedCategoryItem.platforms}}</p>
          </div>

          <!-- LoC -->
          <div id="projectAboutSectionLoC" class="projectAboutSection">
            <p class="projectAboutTitle">Lines of Code</p>
            <p class="projectAboutText">~{{selectedCategoryItem.LoC}}</p>
          </div>

          <!-- features -->
          <div id="projectAboutSectionFeatures" class="projectAboutSection">
            <p class="projectAboutTitle">Features</p>
            <p class="projectAboutText" v-for="(feature, index) in selectedCategoryItem.features"> · {{feature}}</p>
          </div>
                    
          <!-- testing -->
          <div id="projectAboutSectionTesting" class="projectAboutSection" v-if="selectedCategoryItem.testing_instructions">
            <p class="projectAboutTitle">Testing</p>

            <p class="projectAboutText" v-if="selectedCategoryItem.testing_instructions_1">{{selectedCategoryItem.testing_instructions_1}}</p>
            <p class="projectAboutText" v-if="selectedCategoryItem.testing_instructions_2">{{selectedCategoryItem.testing_instructions_2}}</p>
            <p class="projectAboutText" v-if="selectedCategoryItem.testing_instructions_3">{{selectedCategoryItem.testing_instructions_3}}</p>
            <p class="projectAboutText" v-if="selectedCategoryItem.testing_instructions_4">{{selectedCategoryItem.testing_instructions_4}}</p>
            <p class="projectAboutText" v-if="selectedCategoryItem.testing_username">Username: {{selectedCategoryItem.testing_username}}</p>
            <p class="projectAboutText" v-if="selectedCategoryItem.testing_password">Password: {{selectedCategoryItem.testing_password}}</p>
          </div>

          <!-- website -->
          <div id="projectAboutSectionWebsite" class="projectAboutSection" v-if="selectedCategoryItem.official_website != ''">
            <p class="projectAboutTitle">Official Website</p>

            <div id="dataItemLinksWebsites">
              <img id="projectLinkWebsite" class="projectLink" v-bind:src="'/icons-for-links/icon-pwa.png'" v-on:click="linkProjectAbout('website', selectedCategoryItem.official_website)" />
              <!-- <img id="projectLinkWebsite" class="projectLink" v-bind:src="'/icons-for-links/icon-website.png'" v-on:click="linkProjectAbout('website', selectedCategoryItem.official_website)" /> -->
            </div>
          </div>

          <!-- repos -->
          <div id="projectAboutSectionRepos" class="projectAboutSection">
            <p class="projectAboutTitle">Source Code</p>
            <div id="projectRepos">

              <!-- repo: frontend -->
              <div id="projectLinkGithubFrontend" v-if="selectedCategoryItem.github_frontend.name">
                <img class="projectLink" title="Frontend" v-bind:src="'/icons-for-links/icon-github.png'" v-on:click="linkProjectAbout('repo', selectedCategoryItem.github_frontend.link)"/>
                <p class="projectLinkGithubNumber">Frontend</p>
              </div>

              <!-- repo: backend -->
              <div id="projectLinkGithubBackend" v-if="selectedCategoryItem.github_backend.name">
                <img id="" class="projectLink" title="Backend" v-bind:src="'/icons-for-links/icon-github.png'" v-on:click="linkProjectAbout('repo', selectedCategoryItem.github_backend.link)"/>
                <p class="projectLinkGithubNumber">Backend</p>
              </div>

              <!-- repo: rest api -->
              <div id="projectLinkGithubRestAPI" v-if="selectedCategoryItem.github_rest_api.name">
                <img id="" class="projectLink" title="REST API" v-bind:src="'/icons-for-links/icon-github.png'" v-on:click="linkProjectAbout('repo', selectedCategoryItem.github_rest_api.link)"/>
                <p class="projectLinkGithubNumber">REST API</p>
              </div>

              <!-- repo: monolith -->
              <div id="projectLinkGithubMonolith" v-if="selectedCategoryItem.github_monolith.name">
                <img id="" class="projectLink" title="" v-bind:src="'/icons-for-links/icon-github.png'" v-on:click="linkProjectAbout('repo', selectedCategoryItem.github_monolith.link)"/>
                <p class="projectLinkGithubNumber"></p>
              </div>

              <!-- repo: by request -->
              <div id="projectLinkGithubMonolith" v-if="selectedCategoryItem.github_by_request_only == true">
                <p class="projectAboutText">By Request</p> <!-- Ask for Preview -->
              </div>
            </div>
          </div>

        </div>

        <!-- project: preview images -->
        <div id="dataItemCategoryDataPreview"  class="dataItemCategoryData" v-if="selectedCategoryItem.type == 'project'">

          <!-- desktop images -->
          <div id="" v-if="selectedCategoryItem.platform_desktop">
            <p id="projectPreviewTitleDesktop" class="projectPreviewTitle">Desktop</p>
            
            <div id="projectPreviewSectionDesktop" class="projectPreviewSection">
              <div class="previewImageContainer" v-for="item in selectedCategoryItem.images_desktop">
                <img class="previewImageThumbnailDesktop" v-bind:src="item.src" v-on:click="displayImageGallery(item)" />
                <p class="preivewImageId">{{item.id}}</p>
              </div>
            </div>
          </div>
          
          <!-- mobile images -->
          <div id="" v-if="selectedCategoryItem.platform_mobile">
            <p id="projectPreviewTitleMobile" class="projectPreviewTitle">Mobile</p>
            
            <div id="projectPreviewSectionMobile" class="projectPreviewSection">
              <div class="previewImageContainer" v-for="item in selectedCategoryItem.images_mobile">
                <img class="previewImageThumbnailMobile" v-bind:src="item.src" v-on:click="displayImageGallery(item)" />
                <p class="preivewImageId">{{item.id}}</p>
              </div>
            </div>

          </div>

          <!-- preview messages -->
          <div id="projectPreviewDesktopOnly" v-else>
            <p id="projectPreviewDesktopOnlyText" class="projectPreviewTitle">App is Desktop Only</p>
          </div>
        </div>

        <!-- project: installers -->
        <div id="dataItemCategoryDataInstallers" class="dataItemCategoryData" v-if="selectedCategoryItem.type == 'project'">

          <div id="dataItemLinksInstallers">

            <!-- desktop installers -->
            <div id="projectInstallerSectionDesktop" class="projectInstallerSection" v-if="selectedCategoryItem.platform_desktop">
              
              <!-- title -->
              <p class="projectInstallerTitle">Desktop</p>
              <div id="projectDesktopInstallers">
                <!-- windows -->
                <div class="projectInstallerLinkContainer" v-on:click="linkProjectInstaller(selectedCategoryItem, 'windows')">
                  <img id="projectLinkDesktopWindows" class="projectInstaller" v-if="selectedCategoryItem.installer_windows" v-bind:src="'/icons-for-links/icon-windows.png'" title="Windows" />
                </div>
                
                <!-- linux -->
                <div class="projectInstallerLinkContainer" v-on:click="linkProjectInstaller(selectedCategoryItem, 'linux')">
                  <img id="projectLinkDesktopLinux" class="projectInstaller" v-if="selectedCategoryItem.installer_linux" v-bind:src="'/icons-for-links/icon-linux.png'" title="Linux" />
                </div>
  
                <!-- mac -->
                <div class="projectInstallerLinkContainer" v-on:click="linkProjectInstaller(selectedCategoryItem, 'mac')">
                  <img id="projectLinkDesktopMac" class="projectInstaller" v-if="selectedCategoryItem.installer_mac" v-bind:src="'/icons-for-links/icon-mac-white.png'" title="Mac" />
                </div>

                <!-- pwa -->
                <div class="projectInstallerLinkContainer" v-on:click="">
                  <img id="projectLinkDesktopPWA" class="projectInstaller" v-if="selectedCategoryItem.technology_pwa" v-bind:src="'/icons-for-links/icon-pwa.png'" title="PWA" />
                </div>
              </div>
            </div>

            <!-- mobile installers -->
            <div id="projectInstallerSectionMobile" class="projectInstallerSection" v-if="selectedCategoryItem.platform_mobile">

              <!-- title -->
              <p class="projectInstallerTitle">Mobile</p>
              <div id="projectMobileInstallers">
                <!-- android standalone -->
                <div class="projectInstallerLinkContainer" v-if="selectedCategoryItem.installer_android_standalone" v-on:click="linkProjectInstaller(selectedCategoryItem, 'android_standalone')">
                  <img id="projectLinkAndroidStandalone" class="projectInstaller" v-if="selectedCategoryItem.installer_android_standalone" v-bind:src="'/icons-for-links/icon-android.png'" title="Android Standalone" />
                </div>
  
                <!-- android play store -->
                <div class="projectInstallerLinkContainer" v-if="selectedCategoryItem.installer_android_play_store" v-on:click="linkProjectInstaller(selectedCategoryItem, 'android_play_store')">
                  <img id="projectLinkAndroidStore" class="projectInstaller" v-bind:src="'/icons-for-links/icon-android-play-store.png'" title="Google Play Store" />
                </div>

                <!-- pwa -->
                <div class="projectInstallerLinkContainer" v-on:click="">
                  <img id="projectLinkMobilePWA" class="projectInstaller" v-if="selectedCategoryItem.technology_pwa" v-bind:src="'/icons-for-links/icon-pwa.png'" title="PWA" />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- EDUCATION -->
        <!-- <div id="dataItemCategories" v-if="selectedCategoryItem.type == 'education'">
          <div id="dataItemCategoryAbout" class="dataItemCategory" v-on:click="selectProjectDetailsCategory('about')">About</div>
          <p class="dotCategoryItem"> · </p>
        </div> -->

        <div id="educationBanner" v-if="selectedCategoryItem.type == 'education'">
          
          <!-- education: title -->
          <div id="dataItemProjectName">
            {{selectedCategoryItem.name}}
          </div>
        </div>

        <div id="dataItemCategoryDataEducation" class="dataItemCategoryData" v-if="selectedCategoryItem.type == 'education'">

          <!-- education: type -->
          <div id="educationAboutSectionType" class="educationAboutSection">
            <p id="educationTypeTitleMobile" class="educationAboutTitle">Type</p>
            <div id="dataItemEducationOneSentenceDescription">{{selectedCategoryItem.type_of_education}}</div>
          </div>

          <!-- education: purpose -->
          <div id="educationAboutSectionPurpose" class="educationAboutSection">
            <p class="educationAboutTitle">Purpose</p>
            <p class="educationAboutText">{{selectedCategoryItem.purpose}}</p>
          </div>

          <!-- education: website -->
          <div id="" class="educationAboutSection" v-if="selectedCategoryItem.official_website">
            <p class="educationAboutTitle">Official Website</p>
            <div id="" class="educationAboutText" v-on:click="linkEducationAbout(selectedCategoryItem.official_website)">{{selectedCategoryItem.official_website.replace("https://www.", "")}}</div>
          </div>
          
          <!-- education: link -->
          <div id="" class="educationAboutSection" v-if="selectedCategoryItem.link">
            <p class="educationAboutTitle">Course Link</p>
            <div id="" class="educationAboutText" v-on:click="linkEducationAbout(selectedCategoryItem.link)">{{selectedCategoryItem.link.replace("https://www.", "")}}</div>
          </div>
          
          <!-- education: courses -->
          <div id="" class="educationAboutSection" v-if="selectedCategoryItem.courses">
            <p class="educationAboutTitle">IT Courses</p>
            <div id="" class="educationAboutText" v-for="item in selectedCategoryItem.courses" v-on:click="linkEducationAbout(item.link)">
              {{item.code}} · {{item.name}}
            </div>
          </div>

          <!-- education: degree -->
          <div id="" class="educationAboutSection" v-if="selectedCategoryItem.degree">
            <p class="educationAboutTitle">Degree</p>
            <div id="" class="educationAboutText" v-on:click="linkEducationAbout(selectedCategoryItem.degree.link)">{{selectedCategoryItem.degree.name}}</div>
          </div>

        </div>
    </div>


    <!-- MODAL: image gallery -->
    <div id="modalImageGallery">
      
      <!-- gallery arrows -->
      <div id="imageGalleryMobileArrowPrev" v-on:click="galleryImagePrev(selectedGalleryImage)">⬅</div>
      <div id="imageGalleryMobileArrowNext" v-on:click="galleryImageNext(selectedGalleryImage)">➡</div>

      <!-- gallery src -->
      <div id="selectedGalleryImageElement" v-if="selectedGalleryImage">
        <img id="selectedGalleryImageSrc" class="galleryImageMobile" v-if="selectedGalleryImage.type == 'mobile'" v-bind:src="selectedGalleryImage.src" v-on:click.left="galleryImageNext(selectedGalleryImage)" v-on:click.right="galleryImagePrev(selectedGalleryImage)" />
        <img id="selectedGalleryImageSrc" class="galleryImageDesktop" v-else-if="selectedGalleryImage.type == 'desktop'" v-bind:src="selectedGalleryImage.src" v-on:click.left="galleryImageNext(selectedGalleryImage)" v-on:click.right="galleryImagePrev(selectedGalleryImage)" />
        
        <p id="selectedGalleryImageComment" v-if="selectedGalleryImage">{{selectedGalleryImage.id}} · {{selectedGalleryImage.comment}}</p>
      </div>
    </div>

    <!-- MODAL: quick nav -->
    <div id="modalQuickNav">
      <div class="quickNavCategory" v-on:click="setRoute('/projects')">PROJECTS</div>
      <div class="quickNavCategory" v-on:click="setRoute('/education')">EDUCATION</div>
      <div class="quickNavCategory" v-on:click="setRoute('/timeline')">TIMELINE</div>
      <div class="quickNavCategory" v-on:click="setRoute('/about')">ABOUT</div>
    </div>

    <!-- MODAL: image gallery underlay -->
    <div id="modalImageGalleryUnderlay" v-on:click="undisplayImageGalleryModals()">
    </div>

  </div>
</template>


<script>
import {useStore} from 'vuex'
import {computed, onMounted, onUpdated, toRaw} from 'vue'
import {useRouter} from 'vue-router'
import configs from '/fimports/cv-configs.json'
import projects from '/fimports/cv-projects.json'
import education from '/fimports/cv-education.json'
import timeline from '/fimports/cv-timeline.json'
import about from '/fimports/cv-about.json'

export default {
  setup() {
    
    //vue
    const store = useStore()
    const router = useRouter()

    //files
    const projectsJSON = projects
    const educationJSON = education
    const timelineJSON = timeline
    const aboutJSON = about
    
    //variables
    const defaultDomain = configs.DEFAULT_DOMAIN
    const contactGithub = configs.CONTACT_GITHUB
    const contactPlayStore = configs.CONTACT_PLAY_STORE
    const contactEmail = configs.CONTACT_EMAIL
    const contactDiscordUsername = configs.CONTACT_DISCORD_USERNAME
    const contactDiscordId = configs.CONTACT_DISCORD_ID
    const contactX = configs.CONTACT_X
    
    //globals
    var currentCategory = 1
    var currentGalleryImage = 0

    //vuex
    const selectedCategoryData = computed(() => { return store.getters['storage/selectedCategoryData']})
    const selectedCategoryItem = computed(() => { return store.getters['storage/selectedCategoryItem']})
    const selectedGalleryImage = computed(() => { return store.getters['storage/selectedGalleryImage']})


    //lifecycle hook: onMounted
    onMounted(() => {
      
      //log
      console.log("componentMain mounted"); 
      
      //variables
      let cr = router.currentRoute.value.fullPath
      
      //check router path
      if(cr.includes("/projects")) { selectCategory('PROJECTS') }
      else if(cr.includes("/education")) { selectCategory('EDUCATION') }
      else { router.push("/projects"); selectCategory('PROJECTS')}
    })
    

    //lifecycle hook: onUpdated
    onUpdated(() => {

        //log
        console.log("componentMain updated")

        //variables
        let cr = router.currentRoute.value.fullPath
        
        //debugging
        // console.log("cr: " + cr)

        //check router path
        if(cr == "/projects") { selectCategory('PROJECTS') }
        else if(cr == "/education") { selectCategory('EDUCATION') }
        else if(cr == "/timeline") { selectCategory('TIMELINE') }
        else if(cr == "/about") { selectCategory('ABOUT') }
        else if(cr.includes("projects/")) { selectCategoryItem() }
        else if(cr.includes("education/")) { selectCategoryItem() }
    })


    //event listener: keyboard
    document.addEventListener('keyup', function(e) {

        //debugging
        // console.log(e)

        //elements
        let modalImageGallery = document.getElementById("modalImageGallery")
        let modalItemDetails = document.getElementById("modalItemDetails")

        //default
        if(e.code == "Escape") { undisplayModals() }
        else if(e.key == "§") { displayQuickNav() }

        //modal details
        if(modalItemDetails && modalItemDetails.style.display == "block") { return }
    })
      

    //event listener: right click context menu
    document.addEventListener('contextmenu', function(e) {
        e.preventDefault() //prevent default browser right click window
    })

    
    //functions
    function selectCategory(selectedCategory)
    {
      //elements
      let categoryProjects = document.getElementById("categoryProjects")
      let categoryEducation = document.getElementById("categoryEducation")
      let categoryTimeline = document.getElementById("categoryTimeline")
      let categoryAbout = document.getElementById("categoryAbout")
      let modalUnderlay = document.getElementById("modalUnderlay")
      let modalItemDetails = document.getElementById("modalItemDetails")
      let modalQuickNav = document.getElementById("modalQuickNav")

      //reset elements
      categoryProjects.style.opacity = "0.1"
      categoryEducation.style.opacity = "0.1"
      categoryTimeline.style.opacity = "0.1"
      categoryAbout.style.opacity = "0.1"
      modalUnderlay.style.display = "none"
      modalQuickNav.style.display = "none"
      if(modalItemDetails) { modalItemDetails.style.display = "none" }
      
      //select: projects
      if(selectedCategory == 'PROJECTS') 
      { 
        currentCategory = 1
        categoryProjects.style.opacity = "1"
        categoryProjects.scrollIntoView()
        store.dispatch('storage/actionSetSelectedCategoryData', projectsJSON)
      }

      //select: education
      else if(selectedCategory == 'EDUCATION')
      {
        currentCategory = 2
        categoryEducation.style.opacity = "1"
        categoryEducation.scrollIntoView()
        store.dispatch('storage/actionSetSelectedCategoryData', educationJSON) 
      }

      //select: timeline
      else if(selectedCategory == 'TIMELINE')
      {
        currentCategory = 3
        categoryTimeline.style.opacity = "1"
        categoryTimeline.scrollIntoView()
        store.dispatch('storage/actionSetSelectedCategoryData', timelineJSON) 
      }
      
      //select: timeline
      else if(selectedCategory == 'ABOUT')
      {
        currentCategory = 4
        categoryAbout.style.opacity = "1"
        categoryAbout.scrollIntoView()
        store.dispatch('storage/actionSetSelectedCategoryData', aboutJSON) 
      }
      
      resetModals()

      //debugging
      // console.log(selectedCategoryData.value)
    }


    function undisplayModals()
    {
       //elements
       let modalUnderlay = document.getElementById("modalUnderlay")
       let modalItemDetails = document.getElementById("modalItemDetails")
       let modalQuickNav = document.getElementById("modalQuickNav")
       let modalImageGallery = document.getElementById("modalImageGallery")
       let modalImageGalleryUnderlay = document.getElementById("modalImageGalleryUnderlay")
       let selectedCategoryDataSelectedCategory = document.getElementById("selectedCategoryDataSelectedCategory")

       //variables
       let cr = router.currentRoute.value.fullPath
       
       //set correct router path
       if(cr.includes("education")) { router.push("/education") }
       else if(cr.includes("projects")) { router.push("/projects") }
       else { router.push("/") }
       
       //reset elements
       if(modalUnderlay) { modalUnderlay.style.display = "none" }
       if(modalItemDetails) { modalItemDetails.style.display = "none" }
       if(modalQuickNav) { modalQuickNav.style.display = "none" }
       if(selectedCategoryDataSelectedCategory) { selectedCategoryDataSelectedCategory.style.display = "block" }

       //image gallery is displayed
       if(modalImageGalleryUnderlay.style.display == "block") 
       { 
          modalUnderlay.style.display = "block"
          modalItemDetails.style.display = "block"
          modalImageGallery.style.display = "none"
          modalImageGalleryUnderlay.style.display = "none"
          selectedCategoryDataSelectedCategory.style.display = "none"
       }
    }

    function resetModals()
    {
       //elements
       let modalUnderlay = document.getElementById("modalUnderlay")
       let modalItemDetails = document.getElementById("modalItemDetails")
       let modalQuickNav = document.getElementById("modalQuickNav")
       let modalImageGallery = document.getElementById("modalImageGallery")
       let modalImageGalleryUnderlay = document.getElementById("modalImageGalleryUnderlay")
       let selectedCategoryDataSelectedCategory = document.getElementById("selectedCategoryDataSelectedCategory")

       //reset elements
       if(modalUnderlay) { modalUnderlay.style.display = "none" }
       if(modalItemDetails) { modalItemDetails.style.display = "none" }
       if(modalQuickNav) { modalQuickNav.style.display = "none" }
       if(selectedCategoryDataSelectedCategory) { selectedCategoryDataSelectedCategory.style.display = "block" }

       //image gallery is displayed
       if(modalImageGalleryUnderlay.style.display == "block") 
       { 
          modalUnderlay.style.display = "block"
          modalItemDetails.style.display = "block"
          modalImageGallery.style.display = "none"
          modalImageGalleryUnderlay.style.display = "none"
          selectedCategoryDataSelectedCategory.style.display = "none"
       }
    }

    function undisplayImageGalleryModals()
    {
       //elements
       let modalImageGallery = document.getElementById("modalImageGallery")
       let modalImageGalleryUnderlay = document.getElementById("modalImageGalleryUnderlay")
       
       //update elements
       if(modalImageGallery) { modalImageGallery.style.display = "none" }
       if(modalImageGalleryUnderlay) { modalImageGalleryUnderlay.style.display = "none" }
    }


    function selectCategoryItem()
    {
      //debugging
      // console.log(toRaw(item))

      //elements
      let modalItemDetails = ""
      let modalUnderlay = ""
      let dataItemCategoryDataAbout = ""
      let selectedCategoryDataSelectedCategory = ""
      let projectBanner = ""
      let dataItemCategoryDataEducation = ""

      //variables
      let itemType = router.currentRoute.value.params.pathMatch[0]
      let itemId = router.currentRoute.value.params.pathMatch[1]
      let itemExists = false

      //find category item
      for(let obj in selectedCategoryData.value)
      {
        //item exists
        if(itemId == selectedCategoryData.value[obj].id)
        {
          store.dispatch('storage/actionSetSelectedCategoryItem', toRaw(selectedCategoryData.value[obj]))
          itemExists = true
          break
        }

        //item does NOT exist
        else
        {
          itemExists = false
        }
      }
      
      //update elements
      if(itemExists == true)
      {
        //elements
        modalItemDetails = document.getElementById("modalItemDetails")
        modalUnderlay = document.getElementById("modalUnderlay")
        dataItemCategoryDataAbout = document.getElementById("dataItemCategoryDataAbout")
        selectedCategoryDataSelectedCategory = document.getElementById("selectedCategoryDataSelectedCategory")

        //debugging
        // console.log("width: " + screen.width)
        // console.log("height: " + screen.height)
        // console.log("orientation: " + screen.orientation.type)

        //screen is mobile
        if(screen.width < 1000 && screen.orientation.type == "portrait-primary")
        {
          //elements
          projectBanner = document.getElementById("projectBanner")
          dataItemCategoryDataEducation = document.getElementById("dataItemCategoryDataEducation")

          //set mobile specific styling
          if(dataItemCategoryDataEducation) {
            if(projectBanner) { 
              projectBanner.style.borderBottom = "0px solid white"
              projectBanner.style.marginTop = "49px"
            }
            if(dataItemCategoryDataEducation) { dataItemCategoryDataEducation.style.marginTop = "20px"}
          }

          //set desktop specific styling
          else 
          {
            if(projectBanner) 
            { 
              projectBanner.style.borderBottom = "2px solid rgba(255, 255, 255, 0.7)" 
              projectBanner.style.marginTop = "40px"
            }
            if(dataItemCategoryDataEducation) { dataItemCategoryDataEducation.style.marginTop = "40px"}
          }
        }
         
        //reset elements
        if(selectedCategoryDataSelectedCategory) { selectedCategoryDataSelectedCategory.style.display = "none" }
        if(modalUnderlay) { modalUnderlay.style.display = "block" }
        if(modalItemDetails) { modalItemDetails.style.display = "block" }
        if(dataItemCategoryDataAbout) { dataItemCategoryDataAbout.scrollTo(0,0) }
        
        //select about tab if possible
        selectProjectDetailsCategory('about')
      }
      else if(itemExists == false)
      {
        modalItemDetails = document.getElementById("modalItemDetails")
        modalUnderlay = document.getElementById("modalUnderlay")

        modalUnderlay.style.display = "none"
        modalQuickNav.style.display = "none"
      }
    }


    function linkSideMenuContact(type, value)
    {
        //check type
        if(type == 'github') { window.open("https://github.com/" + value, '_blank') }
        else if(type == 'android-play-store') { window.open("https://play.google.com/store/apps/developer?id=" + value, "_blank") }
        else if(type == 'discord') { window.open("https://discord.com/users/" + value, "_blank") }
        else if(type == 'x') { window.open("https://x.com/" + value, "_blank") }
        else if(type == 'email') { document.location.href = "mailto:" + value }
        // else if(type == 'store.app') { window.open("", "_blank") }
    }
    

    function linkEducationAbout(value)
    { 
        //format URL
        value = value.replace("https://www.", "")
        
        //redirect
        window.open("https://www." + value, '_blank')
    }

    
    function linkProjectAbout(type, value)
    {
        //format URL
        value = value.replace("https://www.", "")
        value = value.replace("https://", "")

        //redirect
        if(type == 'website') { window.open("https://" + value, '_blank') }
        else if(type == 'repo') { window.open("https://" + defaultDomain + value, '_blank')}
    }


    function linkProjectInstaller(value, type)
    {
      //debugging
      // console.log("linkProjectInstaller")

      //check installer type
      if(type == 'windows') { window.open(value.installer_windows, '_self') }
      else if(type == 'linux') { window.open(value.installer_linux, '_self') }
      else if(type == 'mac') { window.open(value.installer_mac, '_self') }
      else if(type == 'android_standalone') { window.open(value.installer_android_standalone, '_self') }
      else if(type == 'android_play_store') { window.open('https://play.google.com/store/apps/details?id=' + value.installer_android_play_store, '_self') }
    }


    function contactInfo()
    {
      //elements
      let sideMenuSectionContact = document.getElementById("sideMenuSectionContact")

      //update elements
      if(sideMenuSectionContact.style.display == "block" || sideMenuSectionContact.style.display == "") 
      { sideMenuSectionContact.style.display = "none" }
      else { sideMenuSectionContact.style.display = "block" }
    }


    function selectProjectDetailsCategory(type)
    {
      //debugging
      // console.log("selectProjectDetailsCategory")
      
      //elements
      let dataItemCategoryDataAbout = document.getElementById("dataItemCategoryDataAbout")
      let dataItemCategoryDataPreview = document.getElementById("dataItemCategoryDataPreview")
      let dataItemCategoryDataInstallers = document.getElementById("dataItemCategoryDataInstallers")
      let dataItemCategoryAbout = document.getElementById("dataItemCategoryAbout")
      let dataItemCategoryPreview = document.getElementById("dataItemCategoryPreview")
      let dataItemCategoryInstallers = document.getElementById("dataItemCategoryInstallers")
      
      //reset elements
      if(dataItemCategoryDataAbout) { dataItemCategoryDataAbout.style.display = "none" }
      if(dataItemCategoryDataPreview) { dataItemCategoryDataPreview.style.display = "none" }
      if(dataItemCategoryDataInstallers) { dataItemCategoryDataInstallers.style.display = "none" }
      if(dataItemCategoryAbout) { dataItemCategoryAbout.style.opacity = "0.1" }
      if(dataItemCategoryPreview) { dataItemCategoryPreview.style.opacity = "0.1" }
      if(dataItemCategoryInstallers) { dataItemCategoryInstallers.style.opacity = "0.1" }

      //update elements
      if(type == "about") 
      { 
        if(dataItemCategoryDataAbout) { dataItemCategoryDataAbout.style.display = "block"; }
        if(dataItemCategoryAbout) { dataItemCategoryAbout.style.opacity = "1" }
        if(dataItemCategoryDataAbout) { dataItemCategoryDataAbout.scrollTo(0,0) }
      }
      else if(type == "preview") 
      { 
        if(dataItemCategoryDataPreview) { dataItemCategoryDataPreview.style.display = "block" }
        if(dataItemCategoryPreview) { dataItemCategoryPreview.style.opacity = "1" }
        if(dataItemCategoryDataPreview) { dataItemCategoryDataPreview.scrollTo(0,0) }
      }
      else if(type == "installers") 
      { 
        if(dataItemCategoryDataInstallers) {dataItemCategoryDataInstallers.style.display = "block" }
        if(dataItemCategoryInstallers) {dataItemCategoryInstallers.style.opacity = "1" }
        if(dataItemCategoryDataInstallers) {dataItemCategoryDataInstallers.scrollTo(0,0) }
      }
    }
    

    function displayImageGallery(imageObj)
    { 
      //debugging
      // console.log(toRaw(imageObj).src)
      
      //elements
      let modalImageGallery = document.getElementById("modalImageGallery")
      let modalImageGalleryUnderlay = document.getElementById("modalImageGalleryUnderlay")
      // let modalUnderlay = document.getElementById("modalUnderlay")

      //update elements
      // modalUnderlay.style.display = "block"
      modalImageGalleryUnderlay.style.display = "block"
      modalImageGallery.style.display = "flex"

      //set vuex
      store.dispatch('storage/actionSetSelectedGalleryImage', toRaw(imageObj))
    }


    function setRoute(value)
    {   
        //debugging
        // console.log("setRoute: " + value)

        //variables
        let routeParams = router.currentRoute.value.params
        let currentRouteString = router.currentRoute.value.fullPath
        let previousRoute = router.options.history.state.back
        let cr = router.currentRoute.value.fullPath

        //null check
        // if(("/" + routeParams.domain + "/" + value) == currentRouteString) { return }
        // else if(newPath == router.currentRoute.value.fullPath.toLowerCase() && newPath.includes("search=")) { return }
        // else if(newPath == router.currentRoute.value.fullPath.toLowerCase()) { undisplayModals(); return }
        // console.log("value: " + value)
        // console.log("cr: " + router.currentRoute.value.fullPath)

        //select category item
        if(router.currentRoute.value.fullPath == value) { selectCategoryItem() }

        //set route URL
        else { router.push(value) }
    }


    function galleryImageNext(imageObj)
    {
      //variables
      let imageType = imageObj.type
      let currentImage = parseInt(imageObj.id)
      let nextImage = currentImage
      let totalImages = 0

      //debugging
      // console.log("currentImage: " + currentImage)
      // console.log("nextImage: " + nextImage)
      // console.log("imageType: " + imageType)

      //preview image desktop
      if(imageType == "desktop")  
      { 
        //set total images
        totalImages = toRaw(selectedCategoryItem.value.images_desktop).length

        //null check
        if(nextImage >= totalImages) { nextImage = 0 }

        //set vuex
        store.dispatch('storage/actionSetSelectedGalleryImage', toRaw(selectedCategoryItem.value.images_desktop)[nextImage])
      }

      //preview image mobile
      else if(imageType == "mobile") 
      { 
        //set total images
        totalImages = toRaw(selectedCategoryItem.value.images_mobile).length

        //null check
        if(nextImage >= totalImages) { nextImage = 0 }
        
        //set vuex
        store.dispatch('storage/actionSetSelectedGalleryImage', toRaw(selectedCategoryItem.value.images_mobile)[nextImage])
      }

      //set global
      currentGalleryImage = nextImage
    }


    function galleryImagePrev(imageObj)
    {
      //set global
      currentGalleryImage-- 

      //variables
      let imageType = imageObj.type
      // let currentImage = parseInt(imageObj.id)
      let prevImage = currentGalleryImage
      let totalImages = 0

      //debugging
      // console.log("currentImage: " + currentImage)
      // console.log("prevImage: " + prevImage)
      // console.log("imageType: " + imageType)

      //preview image desktop
      if(imageType == "desktop")  
      { 
        //set total images
        totalImages = toRaw(selectedCategoryItem.value.images_desktop).length - 1

        //null check
        if(prevImage <= -1) { prevImage = totalImages }
        else if(prevImage == totalImages) { prevImage = 0 }
        
        //set vuex
        store.dispatch('storage/actionSetSelectedGalleryImage', toRaw(selectedCategoryItem.value.images_desktop)[prevImage])
      }

      //preview image mobile
      else if(imageType == "mobile") 
      {
        //set total images
        totalImages = toRaw(selectedCategoryItem.value.images_mobile).length - 1

        //null check
        if(prevImage <= -1) { prevImage = totalImages }
        else if(prevImage == totalImages) { prevImage = 0 }
        
        //set vuex
        store.dispatch('storage/actionSetSelectedGalleryImage', toRaw(selectedCategoryItem.value.images_mobile)[prevImage])
      }
      
      //set global
      currentGalleryImage = prevImage
    }


    function displayQuickNav()
    {
      //elements
      let modalQuickNav = document.getElementById("modalQuickNav")
      let modalUnderlay = document.getElementById("modalUnderlay")
      let modalItemDetails = document.getElementById("modalItemDetails")

      //update elements
      if(modalQuickNav.style.display == "none" || modalQuickNav.style.display == "")
      {
        modalUnderlay.style.display = "block"
        modalQuickNav.style.display = "flex"
        if(modalItemDetails) { modalItemDetails.style.display = "none" }
      }
      else
      {
        modalUnderlay.style.display = "none"
        modalQuickNav.style.display = "none"
      }
    }


    return {
      //variables
      contactGithub,
      contactPlayStore,
      contactEmail,
      contactDiscordUsername,
      contactDiscordId,
      contactX,
      selectedCategoryData,
      selectedCategoryItem,
      selectedGalleryImage,
      router,

      //functions
      undisplayModals,
      undisplayImageGalleryModals,
      selectCategory,
      selectCategoryItem,
      selectProjectDetailsCategory,
      linkSideMenuContact,
      linkEducationAbout,
      linkProjectAbout,
      linkProjectInstaller,
      contactInfo,
      displayImageGallery,
      galleryImageNext,
      galleryImagePrev,
      setRoute,
    }    
  }
}
</script>


<style scoped>
  /*** scrollbars ***/
  #categoriesMenu::-webkit-scrollbar { height: 0px; width: 0px; }
  #projectLinks::-webkit-scrollbar { height: 0px; width: 0px; }
  #dataItemProjectOneSentenceDescriptionDesktop::-webkit-scrollbar { height: 0px; width: 0px; }
  #dataItemProjectOneSentenceDescription::-webkit-scrollbar { height: 0px; width: 0px; }
  #dataItemEducationOneSentenceDescription::-webkit-scrollbar { height: 0px; width: 0px; }
  #dataItemProjectName::-webkit-scrollbar { height: 0px; width: 0px; }
  #dataItemCategories::-webkit-scrollbar { height: 0px; width: 0px; }
  .selectedCategoryData::-webkit-scrollbar { height: 0px; width: 0px; }
  .categoryDataObj::-webkit-scrollbar { height: 0px; width: 0px; }
  .dataItemCategoryData::-webkit-scrollbar { height: 0px; width: 0px; }
  .projectPreviewSection::-webkit-scrollbar { height: 0px; width: 0px; }
  .categoryDataItemName::-webkit-scrollbar { height: 0px; width: 0px; }
  .educationAboutText::-webkit-scrollbar { height: 0px; width: 0px; }


  /*** ids ***/
  #componentMain 
  { 
    height: 100vh; 
    width: 100vw; 
    margin: 0px; 
    padding: 0px;
    color: white; 
    background-color: #111416; /* #2c3e50 */
    background-image: linear-gradient(to right, rgba(17, 20, 22, 0.992) 0 100%), url('/public/tile-hexagon-gray-gray.png')
  }
  #sideMenu 
  { 
    position: fixed; 
    display: flex; 
    flex-direction: column;
    height: auto; 
    width: 11vw; 
    top: 20px; 
    left: 20px;
    opacity: 1;
    /* border: 1px solid rgba(255, 255, 255, 0.4); */
    /* background-color: black; */
  }
  #categoriesMenu 
  {
    position: fixed;
    display: flex;
    top: 20px;
    margin: auto;
    padding: 10px 0px 10px 0px;
    height: auto;
    width: 30vw;
    left: 34%;
    flex-direction: row;
    justify-content: center;
    user-select: none; 
    user-drag: none; 
    -webkit-user-drag: none; 
    overflow-x: scroll;
    z-index: 0;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    /* border: 1px solid white; */
    /* background-color: black; */
  }
  #categoryProjects { opacity: 1; }
  #sideMenuSectionProfilePicture 
  { 
    display: block; 
    height: 22vh;
    max-height: 210px;
    width: auto;
    min-width: 210px;
    background-image: url("/public/profile-picture.jpg"); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 90%;
    border: 2px solid white;
  }
  #projectLinks 
  { 
    display: flex; 
    flex-direction: row; 
    /* max-width: 22vw;  */
    /* overflow-x: scroll;  */
  }
  #categoryData1 { border-top: 0px solid white; }
  #categoryData13 { border-bottom: 0px solid white; }
  #sideMenuSectionContact
  { 
    display: block;
    margin: 20px 0px 0px -10px; 
    padding: 0px;
  }
  #sideMenuSectionLanguages 
  { 
    margin: 20px 0px 0px 0px; 
    padding: 20px 0px 0px 0px; 
    border-top: 1px solid rgba(255, 255, 255, 0.4); 
  }
  #modalUnderlay { position: fixed; display: none; height: 100vh; width: 100vw; opacity: 0.93; background-color: black; }
  #modalImageGalleryUnderlay { position: fixed; display: none; height: 100vh; width: 100vw; opacity: 1; z-index: 1; background-color: #111416; }
  #modalItemDetails 
  { 
    position: fixed; 
    display: none; 
    height: auto; 
    width: auto;
    min-width: 549px;
    max-width: 549px;
    top: 12vh;
    left: calc(33vw - 12px);
    margin: 0px; 
    padding: 49px;
    user-select: none;
    z-index: 1;
    border: 3px solid rgba(255, 255, 255, 0.3); 
    background-color: #111416; 
  }
  #dataItemCategories 
  { 
    position: absolute;
    display: flex;
    width: -webkit-fill-available;
    margin: 0px;
    padding: 0px 0px 0px 190px;
    top: 136px;
    flex-direction: row; 
    justify-content: left;
    z-index: 1;
    border: 0px solid white;
    /* border-top: 2px solid rgba(255, 255, 255, 0.3);  */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
    border-style: dashed;
    overflow-x: scroll;
  }
  #dataItemProjectIcon 
  { 
    display: block; 
    height: 149px; 
    width: auto;
    margin: 0px 0px 0px -1px;
    padding: 0px;
    user-select: none; 
    user-drag: none; 
    -webkit-user-drag: none;
    border: 1px solid black; 
  }
  #dataItemProjectName 
  { 
    position: absolute; 
    display: block;
    margin: 0px;
    padding: 0px 0px 0px 200px; 
    top: 60px; 
    width: 349px; 
    font-size: 26px; 
    font-weight: bold;
    text-align: left;
    text-shadow: 0px 1px black;
    white-space: nowrap;
    overflow-x: scroll;
    z-index: 0;
  }
  #dataItemProjectOneSentenceDescriptionDesktop 
  { 
    position: absolute; 
    margin: 0px;
    padding: 0px 0px 0px 200px;
    display: block; 
    top: 95px; 
    width: -webkit-fill-available; 
    font-size: 20px;
    font-weight: bold;
    opacity: 0.6;
    text-align: left;
    text-shadow: 0px 1px black;
    white-space: nowrap;
    overflow-x: scroll;
    z-index: 1;
  }
  #dataItemEducationOneSentenceDescription 
  { 
    position: absolute; 
    margin: 0px;
    padding: 0px 0px 0px 200px;
    display: block; 
    top: 95px; 
    width: -webkit-fill-available; 
    font-size: 20px;
    font-weight: bold;
    opacity: 0.6;
    text-align: left;
    white-space: nowrap;
    overflow-x: scroll;
    z-index: -1;
  }
  #dataItemCategoryAbout { opacity: 1; }
  #dataItemCategoryDataAbout { display: block; }
  #dataItemCategoryDataPreview { display: none; margin: 46px 0px 0px 0px; padding: 0px; text-align: left; }
  #dataItemCategoryDataInstallers { display: none; }
  #projectAboutSectionRepos { margin-bottom: 8px; }
  #projectThirdPartyAPIs { display: inline-flex; flex-direction: row; }
  #projectRepos { display: inline-flex; flex-direction: row; }
  #dataItemLinksInstallers { display: flex; flex-direction: column; }
  #aboutItemTitle1 { margin-top: 0px; }
  #categoryItemEducation8 { border-bottom: 0px solid white; }
  #categoryItemProject13 { border-bottom: 0px solid white; }
  #categoryItemTimeline9 { border-bottom: 0px solid white; }
  #projectInstallerSectionMobile { margin-bottom: 12px; }
  #modalImageGallery
  { 
    position: fixed;
    display: none;
    max-height: 70vh;
    width: -webkit-fill-available;
    top: 7vh;
    /* left: 29%; */
    margin: 0px; 
    padding: 0px;
    user-select: none;
    justify-content: center;
    z-index: 2;
    border: 0px solid white; 
    background-color: transparent; /* #111416 */
  }
  #educationAboutSectionPurpose { margin-top: 0px; }
  #projectPreviewTitleDesktop { margin-top: -4px; }
  #selectedGalleryImageSrc 
  { 
    display: block; 
    height: auto;
    width: auto; 
    margin: 0px; 
    padding: 0px;
    user-drag: none;
    -webkit-user-drag: none;
    /* border: 1px solid black; */
  }
  #selectedGalleryImageComment 
  { 
    display: block; 
    margin: 20px 0px 20px 0px; 
    padding: 0px; 
    font-weight: bold; 
    font-size: 18px; 
    text-align: center;
    opacity: 0.6;
  }
  #selectedGalleryImageElement 
  { 
    display: flex; 
    flex-direction: column; 
    /* min-height: 70vh; */
    /* max-width: 49vw; */
    margin: 0px;
    padding: 0px;
    background-color: transparent;
  }
  #imageGalleryMobileArrowPrev { display: none; }
  #imageGalleryMobileArrowNext { display: none; }
  #imageGalleryClose 
  { 
    position: absolute;
    display: block; 
    height: min-content;
    bottom: -16vh;
    width: auto; 
    margin: 0px;
    padding: 0px;
    font-weight: bold; 
    font-size: 40px;
    opacity: 0.6;
  }
  #modalQuickNav
  {
    position: fixed;
    display: none;
    height: auto;
    width: 20vw;
    top: 20%;
    left: 39vw;
    flex-direction: column;
    margin: auto;
    padding: 0px;
    z-index: 3;
    /* opacity: 0.93; */
    background-color: transparent;
  }
  #aboutCategoryTitleGeneral { margin-top: 0px; }
  #selectedCategoryDataSelectedCategory { display: flex; }
  #projectAboutSectionSlogan { display: none; }
  #educationTypeTitleMobile { display: none; }
  #dataItemCategoryDataEducation { margin-top: 0px; }
  #projectPreviewDesktopOnly { display: none; }


  /*** classes ***/
  .selectedCategoryData 
  { 
    position: fixed;
    display: flex;
    top: 8vh;
    left: calc(34% + 1px);
    max-height: 80vh;
    width: 26vw;
    margin: 0px;
    padding: 30px 30px 30px 40px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    user-select: none; 
    user-drag: none; 
    -webkit-user-drag: none;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
  }
  .category { font-size: 24px; font-weight: bold; user-select: none; opacity: 0.1; text-shadow: 0px 1px black; }
  .dotCategory { margin: 6px 0px 0px 20px; font-weight: bold; opacity: 0.4; visibility: hidden; }
  .dotDataItem { margin: 3px 10px 0px 10px; font-weight: bold; opacity: 0.4; }
  .dotCategoryItem { margin: 24px 3px 0px 3px; font-weight: bold; font-size: 16px; opacity: 0.1; }
  .dotEducationItem { margin: 9px 9px 0px 8px; font-weight: bold; font-size: 16px; opacity: 0.1; }
  .dotTimelineItem { margin: 18px 8px 0px 8px; font-weight: bold; font-size: 16px; opacity: 0.1; }
  .dotProjectName { margin: 0px 10px 0px 10px; font-weight: bold; font-size: 16px; opacity: 0.1; }
  .categoryDataObj 
  { 
    display: flex; 
    flex-direction: row;
    height: auto; 
    width: -webkit-fill-available;
    margin: 0px; 
    padding: 9px 0px 8px 0px;
    user-select: none; 
    user-drag: none; 
    -webkit-user-drag: none;
    align-items: center;
    overflow-y: hidden;
    opacity: 0.9;
    /* opacity: 0.2; */
    /* overflow-x: scroll; */
    border-bottom: 1px solid rgba(255, 255, 255, 0.03); 
  }
  /* .categoryDataObj:hover { opacity: 1 } */
  /* .categoryDataObj:active { opacity: 1 } */
  .categoryItem { font-size: 20px; font-weight: bold; user-select: none; white-space: nowrap; }
  .sideMenuSection { height: 20%; width: 100%; }
  .sideMenuItem 
  { 
    display: block;
    margin: 4px 0px 4px 0px;
    margin-left: 8px;
    padding: 0px; 
    font-size: 19px; 
    font-weight: bold; 
    text-align: left;
    user-select: none;
    white-space: nowrap;
  }
  .categoryDataItemIcon 
  { 
    height: 36px; 
    width: 36px;
    margin: 0px 6px 0px 0px;
    user-select: none; 
    user-drag: none;
    -webkit-user-drag: none;
    border: 1px solid transparent; 
    }
  .categoryDataItemLink 
  { 
    max-height: 30px; 
    min-height: 30px; 
    max-width: 30px; 
    min-width: 30px; 
    margin: 0px 4px 0px 4px;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    /* border-radius: 90%; */
    border: 0px solid white;
    background-color: transparent;
  }
  .projectLink 
  { 
    height: 40px;
    width: 40px;
    margin: 3px 13px 0px 0px;
    padding: 0px;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    /* border-radius: 90%; */
    border: 0px solid #ffffff33;
    /* background-color: white; */
  }
  .projectInstaller 
  { 
    max-height: 49px; 
    min-height: 49px; 
    max-width: 49px;
    min-width: 49px; 
    margin: 0px 20px 0px 0px;
    padding: 0px;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    /* border-radius: 90%; */
    border: 0px solid #ffffff33;
    /* background-color: white; */
  }
  .sideMenuContactLinkIcon 
  { 
    height: 40px;
    width: 40px;
    margin: 0px;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    /* border-radius: 90%; */
    border: 1px solid #111416;
    background-color: black;
  }
  .categoryDataItemName 
  { 
    width: -webkit-fill-available;
    /* max-width: 249px;  */
    /* min-width: 249px;  */
    margin-left: 6px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 0px 1px black;
    white-space: nowrap;
  }
  .categoryDataItemId 
  { 
    width: min-content;
    /* max-width: 249px;  */
    /* min-width: 249px;  */
    /* margin-left: 16px; */
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.1;
    white-space: nowrap;
  }
  .educationDataItem
  { 
    display: inline-flex;
    flex-direction: row;
    width: -webkit-fill-available;
    /* max-width: 249px;  */
    /* min-width: 249px;  */
    /* margin-left: 20px; */
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
  }
  .sideMenuContactLinkContainer 
  {
     display: flex; 
     margin: 0px 0px 20px 8px;
     flex-direction: row; 
     align-items: center; 
     user-select: none; 
     user-drag: none; 
     -webkit-user-drag: none; 
  }
  .sideMenuContactName 
  { 
    display: block; 
    margin: 3px 0px 0px 13px; 
    padding: 0px; 
    font-size: 18px; 
    font-weight: bold; 
    text-align: left;
    text-shadow: 0px 1px black;
    opacity: 0.1;
    user-select: all;
    /* visibility: hidden; */
  }
  .categoryItemTimeline 
  { 
     display: flex;
     flex-direction: row;
     margin: 0px; 
     padding: 8px 0px 8px 0px;
     text-align: left;
     font-size: 20px;
     border-bottom: 1px solid rgba(255, 255, 255, 0.03)
     /* border-left: 4px solid white;  */
  }
  .timelineDate 
  { 
    display: inline-flex; 
    margin: 4px 10px 4px 0px; 
    padding: 10px 16px 10px 16px; 
    font-size: 24px; 
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    /* border-radius: 90%; */
  }
  .timelineItem
  { 
    display: inline-flex;
    flex-direction: row;
    margin: 16px 0px 0px 6px; 
    padding: 0px; 
    font-size: 20px; 
    font-weight: bold; 
    opacity: 1;
    white-space: nowrap; 
  }
  .timelineText { display: inline-flex; margin: 0px; padding: 0px; text-shadow: 0px 1px black; }
  .dataItemCategory 
  { 
    display: block; 
    margin: 0px; 
    padding: 20px 16px 16px 13px; 
    user-select: none; 
    font-size: 22px; 
    font-weight: bold;
    text-shadow: 0px 1px black;
    opacity: 0.1;
    border: 0px solid white;
  }
  .dataItemCategoryData 
  { 
    display: block;
    height: auto;
    max-height: 40vh; 
    width: -webkit-fill-available;
    margin-top: 40px;
    overflow-y: scroll;
    text-align: left;
    opacity: 0.9;
    /* border-bottom: 2px solid rgba(255, 255, 255, 0.3); */
  }
  .projectAboutTitle 
  { 
    display: block; 
    margin: 0px 0px 4px 0px; 
    padding: 0px 0px 0px 0px; 
    font-weight: bold; 
    font-size: 21px; 
    text-shadow: 0px 1px black; 
  }
  .projectAboutText 
  { 
    display: block; 
    margin: 0px; 
    padding: 0px 0px 3px 0px; 
    font-weight: bold; 
    font-size: 20px; 
    opacity: 0.6;
    user-select: text;
    text-shadow: 0px 1px black;
  }
  .projectAboutSection { margin: 0px 0px 49px 0px; padding: 0px; border-bottom: 0px solid white; }
  .educationAboutSection { margin: 49px 0px 0px 0px; padding: 0px; border-bottom: 0px solid white; }
  .projectInstallerTitle { display: block; margin: 0px 0px 8px 0px; padding: 0px 0px 0px 0px; font-weight: bold; font-size: 21px; }
  .projectInstallerSection { margin: 0px 0px 49px 0px; padding: 0px; border-bottom: 0px solid white; }
  .projectPreviewTitle { display: block; margin: 0px 0px 8px 0px; padding: 0px 0px 0px 0px; font-weight: bold; font-size: 21px; text-align: left; }
  .projectPreviewSection 
  { 
    display: flex; 
    flex-direction: row; 
    margin: 0px 0px 49px 0px; 
    padding: 0px;
    overflow-x: scroll;
    border-bottom: 0px solid white; 
  }
  .projectLinkGithubNumber 
  { 
    display: block; 
    margin: 4px 13px 0px 0px; 
    padding: 0px; 
    font-weight: bold; 
    font-size: 8px;
    opacity: 0.6;
    text-align: center; 
  }
  .previewImageThumbnailDesktop 
  { 
    display: inline-block; 
    height: 100px; 
    width: auto;
    margin: 0px 12px 0px 0px; 
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .previewImageThumbnailMobile 
  { 
    display: inline-block; 
    height: 200px; 
    width: auto; 
    margin: 0px 4px 0px 0px; 
    padding: 0px;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    /* border: 1px solid white;  */
  }
  .educationItemId { display: inline-block; margin: 6px 2px 0px 0px; padding: 0px; opacity: 0.1; }
  .educationItemType { display: inline-block; margin: 0px; padding: 0px; }
  .educationItemName { display: inline-block; margin: 8px 0px 0px 4px; padding: 0px; text-shadow: 0px 1px black; }
  .categoryEducationIcon { border-radius: 0%; }
  .educationAboutTitle { display: block; margin: 0px 0px 4px 0px; padding: 0px 0px 0px 0px; font-weight: bold; font-size: 21px; }
  .educationAboutText 
  { 
    display: block; 
    margin: 0px; 
    padding: 0px 0px 0px 0px; 
    font-weight: bold; 
    font-size: 20px;
    opacity: 0.6; 
    /* white-space: nowrap; */
    /* overflow-x: scroll;  */
  }
  .aboutItemText 
  { 
    display: block; 
    margin: 0px; 
    padding: 0px; 
    font-size: 20px; 
    font-weight: bold; 
    text-align: left;
    text-transform: capitalize;
    opacity: 0.6;
  }
  .aboutItemTitle 
  { 
    display: block; 
    margin: 44px 0px 0px 0px; 
    padding: 0px; 
    font-size: 24px; 
    font-weight: bold;
    text-align: left; 
    text-transform: uppercase; 
  }
  .aboutItem { display: block; margin: 0px 0px 49px 0px; }
  .aboutItemIconSquare 
  { 
    display: inline-block; 
    height: 40px; 
    width: 40px; 
    margin: 0px; 
    padding: 0px;
    opacity: 0.9;
    border-radius: 0%;
    border: 1px solid transparent; 
  }
  .aboutItemIconRounded 
  { 
    display: inline-block; 
    height: 40px; 
    width: 40px; 
    margin: 0px; 
    padding: 0px;
    opacity: 0.9;
    border-radius: 90%; 
    border: 1px solid transparent; 
  }
  .aboutItemIconText 
  { 
    display: inline-block;
    margin: 10px 0px 0px 13px; 
    padding: 0px; 
    font-size: 20px; 
    font-weight: bold; 
    opacity: 0.6; 
    vertical-align: top; 
  }
  .aboutItemContainer { display: flex; width: max-content; margin: 0px 0px 12px 0px; padding: 0px; }
  .categoryDataItemIdBelow10 { padding-left: 10px; }
  .preivewImageId { display: block; margin: 4px 0px 0px 0px; padding: 0px; text-align: center; font-weight: bold; font-size: 18px; opacity: 0.3; }
  .quickNavCategory 
  { 
    display: block; 
    margin: 0px 0px 40px 0px; 
    padding: 0px; 
    font-size: 40px; 
    font-weight: bold;
    user-select: none;
  }
  .projectInstallerLinkContainer { display: inline-block; }
  .aboutCategoryTitle { display: block; margin: 40px 0px 0px 0px; padding: 0px; font-weight: bold; font-size: 22px; }
  .iconRound { border-radius: 90%; }
  .iconSquare { border-radius: 0%; }
  .galleryImageMobile { max-height: auto; max-width: 49vw; }
  .galleryImageDesktop { max-height: 78vh; max-width: auto; }


  /*** mobile portrait ***/
  @media screen and (max-width: 1000px) and (orientation: portrait)
  {
    #dataItemLinksTechnologies::-webkit-scrollbar { height: 0px; width: 0px; }
    #dataItemProjectName::-webkit-scrollbar { height: 0px; width: 0px; }
    #sideMenuSectionContact::-webkit-scrollbar { height: 0px; width: 0px; }
    .categoryItemTimeline::-webkit-scrollbar { height: 0px; width: 0px; }
    .educationAboutText::-webkit-scrollbar { height: 0px; width: 0px; }
    .educationItemName::-webkit-scrollbar { height: 0px; width: 0px; }
    .projectAboutText::-webkit-scrollbar { height: 0px; width: 0px; }

    #sideMenuSectionProfilePicture { display: none; }
    #modalQuickNav { left: 22vw; }
    #modalItemDetails 
    { 
      min-width: initial; 
      max-width: initial; 
      width: -webkit-fill-available; 
      top: 0px; 
      left: 0px; 
      margin: 0px; 
      padding: 26px;
      border: 0px solid white;
    }
    #sideMenuSectionContact 
    { 
      position: fixed; 
      display: flex;
      width: 90vw;
      bottom: 3vh;
      left: 7vw;
      margin: 0px;
      padding: 0px; 
      flex-direction: row;
      overflow-x: scroll;
      justify-content: center;
    }
    #dataItemProjectName { width: 87%; top: 20px; margin: 0px; padding: 0px; font-size: 28px; text-align: center; overflow-x: scroll; }
    #dataItemCategoryDataPreview { margin-top: 30px; margin-left: -10px; }
    #dataItemCategoryDataInstallers { margin-top: 40px; }
    #dataItemCategories 
    { 
      position: relative; 
      width: -webkit-fill-available; 
      top: 0px; 
      flex-direction: row; 
      margin: 0px 0px 0px -22px; 
      padding: 22px 0px 20px 0px; 
      justify-content: center; 
    }
    #dataItemProjectOneSentenceDescription 
    { 
      position: relative;
      display: block;
      top: 0px;
      width: auto;
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      font-weight: bold;
      text-shadow: 0px 1px black;
      opacity: 0.6;
      white-space: nowrap;
      user-select: text;
      overflow-x: scroll; 
    }
    #dataItemEducationOneSentenceDescription { position: relative; top: 0px; width: min-content; margin: 0px; padding: 0px; }
    #dataItemLinksTechnologies { display: flex; flex-direction: row; max-width: 90vw; overflow-x: scroll; }
    #dataItemCategoryDataPreview { overflow-y: hidden; }
    #categoriesMenu { width: 83vw; top: 2vh; left: 7vw; justify-content: left; }
    #projectBanner { height: auto; width: -webkit-fill-available; margin-top: 40px; border-bottom: 2px solid rgba(255, 255, 255, 0.7); }
    #projectPreviewTitleDesktop { margin-top: 0px; }
    #selectedGalleryImageElement { max-width: auto; max-height: auto; margin-top: 2vh; }
    #modalImageGallery { top: 0px; }
    #imageGalleryMobileArrowNext { position: fixed; display: block; top: 40vh; right: 2vw; margin: 0px; padding: 0px; font-size: 42px; opacity: 0.6; }
    #imageGalleryMobileArrowPrev { position: fixed; display: block; top: 40vh; left: 2vw; margin: 0px; padding: 0px; font-size: 42px; opacity: 0.6; }
    #projectPreviewTitleDesktop { display: none; }
    #projectPreviewSectionDesktop { display: none; }
    #projectPreviewTitleMobile { display: none; }
    #educationAboutSectionPurpose { margin: 0px; }
    #educationAboutSectionType { margin: 0px 0px 40px 0px; }
    #dataItemProjectIcon { display: block; height: 100px; margin-left: calc(27vw + 3px); border: 1px solid black; }
    #projectAboutSectionTechnologies { width: 100%; }
    #projectAboutSectionWhy { width: auto; }
    #projectAboutSectionSlogan { display: block; }
    #dataItemProjectOneSentenceDescriptionDesktop { display: none; }
    #educationTypeTitleMobile { display: block; }
    #projectPreviewDesktopOnly { display: block; width: max-content; margin: 0px 0px 0px 18vw; white-space: nowrap; }
    /* #projectAboutSectionFeatures { width: 70%; } */
    /* #projectPreviewTitleDesktop { display: none; } */
    /* #projectPreviewSectionDesktop { display: none; } */
    /* #projectInstallerSectionDesktop { display: none; } */
    
    .selectedCategoryData 
    { 
      display: block; 
      height: initial;
      max-height: 78vh;
      width: 87vw; 
      top: 10vh; 
      left: 7vw; 
      margin: 0px; 
      padding: 0px;
      border: 0px solid white;
      background-color: transparent; 
    }
    .dotCategoryItem { display: none; }
    .dataItemCategory { margin: 0px 0px 4px 24px; padding: 0px; }
    .sideMenuContactName { display: none; }
    .sideMenuContactLinkContainer { margin: 0px 16px 0px 0px; padding: 0px; }
    .sideMenuSection { height: initial; width: initial; }
    .categoryItemTimeline { overflow-x: scroll; }
    /* .categoryItemEducation { overflow-x: scroll; } */
    .categoryDataItemName { font-size: 18px; overflow-x: scroll; }
    .timelineDate { padding: 6px; font-size: 23px; }
    .timelineItem { margin: 13px 0px 0px 6px; }
    .projectAboutTitle { width: max-content; }
    .projectAboutText { width: 100%; padding: 2px 0px 2px 0px; overflow-x: scroll; white-space: nowrap; overflow-x: scroll; }
    .projectAboutSection { width: min-content; max-width: 86vw; margin: 0px 0px 49px 0px; }
    .dataItemCategoryData { max-height: 40vh; width: min-content; }
    .projectPreviewTitle { text-align: left; }
    .projectPreviewSection { max-width: 86vw; overflow-x: scroll; }
    .projectInstallerSection { display: block; width: 86vw; flex-direction: row; }
    .aboutItemTitle { margin: 34px 0px 0px 0px; }
    .educationAboutTitle { width: max-content; max-width: 86vw; }
    .educationAboutText { width: max-content; max-width: 86vw; white-space: nowrap; overflow-x: scroll; }
    .educationAboutSection { margin: 42px 0px 0px 0px; }
    .dotTimelineItem { margin: 16px 6px 0px 6px; }
    .categoryDataItemId { display: none; }
    .dotProjectName { display: none; }
    .educationItemId { display: none; }
    .dotEducationItem { display: none; }
    .educationItemName { width: 90%; margin-top: 10px; margin-left: 6px; font-size: 18px; overflow-x: scroll; }
    .galleryImageMobile { max-width: 80vw; }
  }

  /*** mobile landscape ***/
  @media screen and (max-width: 1000px) and (orientation: landscape)
  {
    #componentMain { display: none; }
  }
</style>