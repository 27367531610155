import { createStore } from 'vuex'


export default createStore({
  modules: { storage: { namespaced: true,

    state: 
    {
      selectedCategoryData: null,
      selectedCategoryItem: null,
      selectedGalleryImage: null,
    },
    
    getters: 
    {
      selectedCategoryData(state) { return state.selectedCategoryData },
      selectedCategoryItem(state) { return state.selectedCategoryItem },
      selectedGalleryImage(state) { return state.selectedGalleryImage },
    },

    mutations:
    {
      mutationSetSelectedCategoryData (state, value) { state.selectedCategoryData = value },
      mutationSetSelectedCategoryItem (state, value) { state.selectedCategoryItem = value },
      mutationSetSelectedGalleryImage (state, value) { state.selectedGalleryImage = value },
    },

    actions: 
    {
      actionSetSelectedCategoryData({commit}, value) { commit('mutationSetSelectedCategoryData', value) },
      actionSetSelectedCategoryItem({commit}, value) { commit('mutationSetSelectedCategoryItem', value) },
      actionSetSelectedGalleryImage({commit}, value) { commit('mutationSetSelectedGalleryImage', value) },
    },


  }
}})
