//imports
import { createRouter, createWebHistory } from 'vue-router'
import viewMain from '../views/viewMain.vue'


//routes
const routes = [
  //start
  { path: '/', name: 'start', component: viewMain },

  //error
  { path :'/:pathMatch(.*)*', name: 'error', component: viewMain }
]


//exports
const router = createRouter({history: createWebHistory(process.env.BASE_URL), routes})
export default router
