<template>
  <div id="viewMain">
    <ComponentMain />
  </div>
</template>

<script>
import ComponentMain from '@/components/componentMain.vue'

export default {
  name: 'Main',

  components: {
    ComponentMain
  },
}
</script>

<style>
body { margin: 0px; padding: 0px; }

#viewMain { margin: 0px; padding: 0px; }
</style>
